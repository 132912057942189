import React, { ReactChild } from 'react'
import styled, { keyframes, ThemeProvider } from 'styled-components'

import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'

import Head from '../components/Head'
import { media } from '../styles/styleUtils'

import logoSombrero from '../images/logos/logo-sombrero-restaurante-borrasco.png'

//import barricas from '../images/imagenes/barricas.jpg'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

interface ILayoutProps {
  children: ReactChild
  location: {
    pathname: string
  }
}

export const Wrapper = styled.div`
  display: block;
  margin: 2.25rem;
  ${media.md`
    margin: 1rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

// const WrapperPrimeraImagen = styled.div`
//   display: flex;
//   width: 100%;
//   ${media.md`

//   `}
// `
const WrapperPrimerTexto = styled.div`
  margin-top: 1rem;
  background-color: black;
  color: white;
  padding: 5rem;
  display: flex;
  width: 100%;
  ${media.md`
  padding: 2rem;
  display:none;
  `}
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`

const PrimeraImagen = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  animation: ${scale} 5s forwards;
  ${media.md`
  `}
`
const WrapperTextoQuienesSomos = styled.div`
  text-align: center;
  margin-top: 1rem;
  ${media.md`

  `}
`

const ParrafoQuienesSomos = styled.p`
  text-align: center;
  margin-top: 1rem;
  ${media.md`

  `}
`

const WrapperContacto = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  iframe {
    width: 100%;
    height: 450px;
    border: none;
  }
  div {
    padding-left: 1rem;
  }
  ${media.md`
    display: block;
    div {
      width: 100%;
      text-align: center;
    }
    iframe {
    margin-top: 2rem;
    width: 100%;
  }
  `}
`
const Horarios = styled.div`
  order: 1;
  h4 {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.3rem;
  }
  ${media.md`
    width: 100%;
    margin-top: 2rem;
`}
`

const ParrafoHorarios = styled.p`
  text-align: left;
  margin-top: 0.2rem;
  ${media.md`
    text-align:center;
  `}
`

const ParrafoBold = styled.p`
  text-align: left;
  font-weight: bold;
  margin-top: 1rem;
  ${media.md`
    text-align: center;
  `}
`

const LogoSombrero = styled.img`
  width: 2rem;
  margin-top: 1rem;
  ${media.md`

  `}
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  ${media.md`

  `}
`

const TituloDeLaWeb = styled.h1`
  font-size: 4rem;
  text-align: center;
  ${media.md`
  font-size: 2rem;
  `}
`

const SubTituloDeLaWeb = styled.h1`
  font-size: 2rem;
  text-align: center;
  ${media.md`
  font-size: 1rem;
  `}
`

const ContenedorLaCarta = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.md`
    display: block;
  `}
`

const ImagenDeLaCarta = styled(Img)`
  width: 100%;
  transition: all 1.5s ease-out;
  transition-property: all;
  transition-duration: 1.5s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  ${media.md`
    
  `}
`

const ImagenSombrero = styled(Img)`
  width: 100%;
  text-align: center;
  margin: 0auto;
`

const TituloLaCarta = styled.h3`
  font-size: 4rem;
  text-align: center;
  ${media.md`
    font-size: 2rem;
  `}
`

const WrapperSeccionCarta = styled.div`
  position: relative;
  width: 100%;
  padding: 0.2rem;
  cursor: pointer;
  ${media.md`
  `}
`

const WrapperHero = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 5%;
  ${media.md`
  `}
`
const IframeContacto = styled.iframe`
  width: 100%;
`
const TextoCarta = styled.h3`
  position: absolute;
  bottom: 2rem;
  color: white;
  font-weight: 900;
  right: 3.5rem;
  font-size: 1.8rem;
  text-align: center;
  text-shadow: 1px 1px grey;
  z-index: 2;
  ${media.md`
    font-size: 3rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
  `}
`

export default ({ children, location }: ILayoutProps) => {
  const Query = useStaticQuery(graphql`
    query Query {
      ComedorPrincipal: file(
        relativePath: {
          eq: "imagenes/comedor-del-restaurante-borrasco-de-lerin.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      LogoSombrero: file(relativePath: { eq: "imagenes/menus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      EsparragosRellenos: file(
        relativePath: { eq: "imagenes/esparragos-rellenos.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ManitasDeCerdo: file(
        relativePath: { eq: "imagenes/manitas-de-cerdo-central.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Torrijas: file(relativePath: { eq: "imagenes/torrijas-central.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Kokotxas: file(relativePath: { eq: "imagenes/kokotxas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Bodega: file(relativePath: { eq: "imagenes/bodega-cuadrada.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>

        <WrapperBody>
          <WrapperHero>
            <PrimeraImagen
              alt={'Logo sombrero'}
              fluid={Query.ComedorPrincipal.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
            ></PrimeraImagen>
          </WrapperHero>
          <TituloDeLaWeb>Restaurante Borrasco</TituloDeLaWeb>
          <SubTituloDeLaWeb>
            Cocina tradicional en Lerín, con toques de cocina moderna
          </SubTituloDeLaWeb>
          {/* <WrapperPrimerTexto>
            <div>
              <h2>
                <a href="">Haz tu Reserva para llevar</a>
              </h2>
              <h5>
                Debido a la situación excepcional que estamos viviendo, contamos
                con servicio para recoger pedidos.
              </h5>
            </div>
          </WrapperPrimerTexto> */}
          <TituloLaCarta>Nuestra carta</TituloLaCarta>
          <ContenedorLaCarta>
            <WrapperSeccionCarta>
              <Link to="/la-carta">
                <TextoCarta>ENTRANTES</TextoCarta>
                <ImagenDeLaCarta
                  alt={'Plato de matinas de cerdo'}
                  fluid={Query.EsparragosRellenos.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                ></ImagenDeLaCarta>
              </Link>
            </WrapperSeccionCarta>

            <WrapperSeccionCarta>
              <Link to="/la-carta#pescados">
                <TextoCarta>PESCADOS</TextoCarta>
                <ImagenDeLaCarta
                  alt={'Plato de kokotxas'}
                  fluid={Query.Kokotxas.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                ></ImagenDeLaCarta>
              </Link>
            </WrapperSeccionCarta>
            <WrapperSeccionCarta>
              <Link to="/la-carta#carnes">
                <TextoCarta>CARNES</TextoCarta>
                <ImagenDeLaCarta
                  alt={'Plato de matinas de cerdo'}
                  fluid={Query.ManitasDeCerdo.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                ></ImagenDeLaCarta>
              </Link>
            </WrapperSeccionCarta>
          </ContenedorLaCarta>
          <ContenedorLaCarta>
            <WrapperSeccionCarta>
              <Link to="/la-carta#postres">
                <TextoCarta>POSTRES</TextoCarta>
                <ImagenDeLaCarta
                  alt={'Plato de torrijas'}
                  fluid={Query.Torrijas.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                ></ImagenDeLaCarta>
              </Link>
            </WrapperSeccionCarta>
            <WrapperSeccionCarta>
              <TextoCarta>MENÚS</TextoCarta>
              <Link to="/menus">
                <ImagenSombrero
                  alt={'Logo sombrero'}
                  fluid={Query.LogoSombrero.childImageSharp.fluid}
                ></ImagenSombrero>
              </Link>
            </WrapperSeccionCarta>
            <WrapperSeccionCarta>
              <Link to="/la-carta#bodega">
                <TextoCarta>BODEGA</TextoCarta>
                <ImagenDeLaCarta
                  alt={'Sección de bodega'}
                  fluid={Query.Bodega.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                ></ImagenDeLaCarta>
              </Link>
            </WrapperSeccionCarta>
          </ContenedorLaCarta>
          <WrapperTextoQuienesSomos>
            <ParrafoQuienesSomos>
              Cocina tradicional con los mejores productos del mercado
            </ParrafoQuienesSomos>
            <LogoSombrero
              alt="Logo del sombrero del Restaurante Borrasco"
              src={logoSombrero}
            ></LogoSombrero>
            <ParrafoQuienesSomos>
              Herencia de nuestros antepasados
            </ParrafoQuienesSomos>
            <ParrafoQuienesSomos>
              con toques de cocina moderna.
            </ParrafoQuienesSomos>
            <LogoSombrero
              alt="Logo del sombrero del Restaurante Borrasco"
              src={logoSombrero}
            ></LogoSombrero>
            <ParrafoQuienesSomos>
              En restaurante Borrasco te sentirás como en casa.
            </ParrafoQuienesSomos>
          </WrapperTextoQuienesSomos>
          <WrapperContacto>
            <IframeContacto
              title="Mapa del restaurante Borrasco de Lerín en google"
              src="https://maps.google.es/maps?f=q&amp;source=s_q&amp;hl=es&amp;geocode=&amp;q=restaurante+borrasco+lerin&amp;aq=&amp;sll=42.483832,-1.972615&amp;sspn=0.006741,0.016512&amp;ie=UTF8&amp;hq=restaurante+borrasco&amp;hnear=Ler%C3%ADn,+Navarra&amp;t=m&amp;z=16&amp;iwloc=A&amp;output=embed"
            ></IframeContacto>
            <Horarios>
              <h4>HORARIOS</h4>
              <ParrafoBold>Lunes a jueves: </ParrafoBold>
              <ParrafoHorarios>
                Comidas. 13:00 - 15:30. Noches cerrado.
              </ParrafoHorarios>
              <ParrafoBold>Viernes y sábado:</ParrafoBold>
              <ParrafoHorarios> Comidas. 13:00 - 15:30.</ParrafoHorarios>
              <ParrafoHorarios>Cenas. 21:00 - 23:00.</ParrafoHorarios>
              <ParrafoBold>Domingo:</ParrafoBold>
              <ParrafoHorarios>
                Comidas. 13:00 - 15:30. Noches cerrado.
              </ParrafoHorarios>
              <br />
              <h4>DONDE ESTAMOS</h4>
              <ParrafoHorarios>Calle Abadejo 2 </ParrafoHorarios>
              <ParrafoHorarios>31260 Lerín, Navarra</ParrafoHorarios>
              <br />
              <h4>RESERVAS</h4>
              <ParrafoHorarios>
                <a href="tel:+34948 53 05 61">948 53 05 61</a>
              </ParrafoHorarios>
              <ParrafoHorarios>
                <a href="tel:+34649943878">649 943 878</a>
              </ParrafoHorarios>
              <ParrafoHorarios>
                <a href="mailto:info@restauranteborrasco.com">
                  info@restauranteborrasco.com
                </a>
              </ParrafoHorarios>
            </Horarios>
          </WrapperContacto>
          <Footer>
            <div>
              Copyright © {new Date().getFullYear()}, Restaurante Borrasco
            </div>
            <div>
              La mejor <strong>cocina tradicional</strong> de Lerín
            </div>
          </Footer>
          <CookieConsent
            location="bottom"
            buttonText="Aceptar"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            Nuestro sitio web utiliza cookies ¿Las aceptas?.{' '}
          </CookieConsent>
        </WrapperBody>
      </Wrapper>
    </>
  )
}
